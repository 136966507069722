const geocoder = new google.maps.Geocoder();

export const getAddressFromGooglePlace = (place) => {
  if (!place || place.address_components?.length == 0 || !place.formatted_address) {
    return;
  }

  const plusCode =
    place.address_components.find((el) => el.types.includes("plus_code"))
      ?.long_name || "";
  let address = place.formatted_address;
  // if (place.formatted_address.indexOf(place.name) == -1) {
  //   address = [place.name, place.formatted_address]
  //     .filter((x) => x)
  //     .join(", ");
  //   address = address.replace(`${plusCode}, `, "");
  // }

  let city =
    place.address_components.find((el) => el.types.includes("locality"))
      ?.long_name ||  place.address_components.find((el) => el.types.includes("neighborhood"))
      ?.long_name || "";

  let state =
    place.address_components.find((el) =>
      el.types.includes("administrative_area_level_1")
    )?.long_name || "";

  let country =
    place.address_components.find((el) => el.types.includes("country"))
      ?.long_name || "";

  let zip =
    place.address_components.find((el) => el.types.includes("postal_code"))
      ?.long_name || "";

  let lat = place.geometry.location.lat();
  let lng = place.geometry.location.lng();

  return {
    address,
    city,
    state,
    country,
    zip,
    lat,
    lng
  }
};

export const getGooglePlaceFromCoordinates = async (lat, lng) => {
  let place = null;
  try {
    if (!lat) {
      throw new Error('lat is missing');
    }
    if (!lng) {
      throw new Error('lng is missing');
    }
    const data = await geocoder.geocode({
      location: new google.maps.LatLng(lat, lng)
    })
    place = data?.results[0]
  } catch (e) {
    console.log(e);
  }
  return place;
}

export const getGooglePlaceFromPlaceId = async (placeId) => {
  let place = null;
  try {
    if (!placeId) {
      throw new Error('placeid missing');
    }
    const data = await geocoder.geocode({
      placeId: placeId
    })
    place = data?.results[0];
  } catch (e) {
    console.log(e);
  }
  return place;
}