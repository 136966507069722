import React, { useEffect, useRef, useState } from "react";
import showToast from "../toastify";
import {
  getAddressFromGooglePlace,
  getGooglePlaceFromCoordinates,
  getGooglePlaceFromPlaceId,
} from "./GooglePlacesUtitlityFunctions";
import { MultiSelect, Modal } from "@mantine/core";

const ProfileGoogleAddressPickerComponent = ({
  address,
  userAddress,
  types,
  className,
  showMap,
  showInfoModal = false,
  page = 'signup',
  oldAddressValue = '',
  setOldAddressValue,
  inputLabel,
  clearable,
  locationError,
  locationErrorMessage,
  useDefaultCurrentLocation,
  showCurrentLocationButton,
  ...props
}) => {
  const [defaultAddress, setDefaultAddress] = useState({});

  const [addressValue, setAddressValue] = useState(userAddress?.address || "");
  // const [oldAddressValue, setOldAddressValue] = useState("")
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [zipModal, setZipModal] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const addressFieldRef = useRef(null);

  const resetAddress = () => {
    setAddressValue("");
    setSelectedAddress({
      addrLine1: "",
      city: "",
      state: "",
      country: "",
      zip: "",
      lat: "",
      lng: "",
    });
  };

  // Initialize Google Autocomplete
  useEffect(() => {
    const addressField = addressFieldRef.current;
    let autocomplete;
    let autocompleteLsr;

    const getAddress = () => {
      const place = autocomplete.getPlace();
      const placeAddress = getAddressFromGooglePlace(place);
      setAddressValue(placeAddress.address);
      if(setOldAddressValue) setOldAddressValue(placeAddress.address);
      setSelectedAddress({
        addrLine1: placeAddress.address,
        city: placeAddress.city,
        state: placeAddress.state,
        country: placeAddress.country,
        zip: placeAddress.zip,
        lat: placeAddress.lat,
        lng: placeAddress.lng,
      });
      setZipCodeError(false);
    };

    autocomplete = new google.maps.places.Autocomplete(addressField, {
      ...(types ? types : {}),
      // componentRestrictions: { country: ["us", "ca"] },
      fields: [
        "name",
        "formatted_address",
        "address_components",
        "geometry",
        "place_id",
      ],
      language: "en",
    });

    autocompleteLsr = autocomplete.addListener("place_changed", getAddress);

    // window.navigator.geolocation.getCurrentPosition(async (position) => {
    //   const currentPlace = await getGooglePlaceFromCoordinates(
    //     position.coords.latitude,
    //     position.coords.longitude
    //   );
    //   const currentAddress = getAddressFromGooglePlace(currentPlace);
    //   setDefaultAddress(currentAddress);
    //   if (!addressValue && useDefaultCurrentLocation) {
    //     setAddressValue(currentAddress.address);
    //   }
    // });

    return () => {
      google.maps.event.removeListener(autocompleteLsr);
      google.maps.event.clearInstanceListeners(autocomplete);
    };
  }, []);

  // Triggers address selected event
  useEffect(() => {

    if (page == 'signup') {
      if(Object.keys(selectedAddress || {}).length > 0)
      props.onChange({ ...selectedAddress });
    } else {
      // if (
      //   (!selectedAddress?.city && (!userAddress))
      // ) {
      //   return;
      // }
      if (props.onChange) {
        if(selectedAddress)
          props.onChange({ ...selectedAddress });
        else if(userAddress) {
          props.onChange({ ...userAddress });
        }
      }
    }
  }, [selectedAddress]);

  const handleBlur  = () => {
    if(!showInfoModal && page !== 'signup') return null;
    if((addressValue.trim().length > 0 && !selectedAddress?.addrLine1) || (addressValue.trim().length > 0 && oldAddressValue !== addressValue))
    setZipCodeError(true);
  else 
    setZipCodeError(false);
  }

  return (
    <>
      <div className={`${zipCodeError ? 'mb-2' : `mb-4`} location-field-cross-icon close-input-icon ${(showInfoModal && page == 'signup') ? className : ''}`}>
        <label htmlFor="autocomplete-address">{inputLabel}{" "}{showInfoModal &&  page.includes('profile') ? <svg onClick={() => setZipModal(true)}
              width="18"
              height="18"
              className="ms-1"
              style={{ cursor: "pointer" }}
              version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 96 96" enableBackground="new 0 0 96 96">  <image id="image0" width="96" height="96" x="0" y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAALEsAACxLAaU9lqkAAAAHdElNRQfnBQ8NDQt9sioqAAAEEElEQVR42u1b7VXjMBAcqEAdsB3g
EtzBpQNcgjuIr4L4KiAdhKuAXAUxFcRU4FwFuR8EkNZyIq2kKPeex3/0CCvN7K4+LQMzZsyYMWPG
f4y7yPUplCA8gkAA6PTXA3oc0OMNHToccou2o0SLPY4Ozw7PKHPT5dQHJ+r6s78NGQu8elM3o1Hl
JO+WMpdjkUFEGej5sQiSEZGMQgpL1JO/vmOLDv1p3PkAQYFApxFqCg1+XsP3NJk4W9RQDvYVXmLH
wR1P1vFmj8aBOpdhc8RwJrYRsIzcBe0ilqnor5L4yyZilYL+86iZjWfaTIGwHtX9HJv+KnGu1qO+
FTUKy1HeF7E9ZBndovWFejT9U3T6dglPcaodGH2VhD4AKOxYokZw1Z4lTzr6NgnB7S1ZdRE8cgE8
kYI6MwUElE67BP8Fc8GStpQLMH1Ri/3oO56Yw8arlH4VMLXwxbavFzeG9UImYC/OfmL0/WdWZaTR
XkLf9H/lZVtaltq+qENjsAvwQHgEADMDvPtBGeB/ILwPADwHPGtYB2agOQo1ghoAMwat3LQSNU5Y
Y8AR24ANT2PMQh4wE4jEBEKhpEmkJ9BLNvqA2Zda2z/cW830o4+8An5r5R+uRupGEmjMRbkZ6T1g
l5U+APTnpzNbChVa+T03f2MOJ38BW8iw/FrLDIHb804rP7qZvJ4PmgNWbB5uAgQs/FdU+iqoEDU6
MAFekxBDcX5NYEsh0sqHgKa/oQJsBQx038nQsAhI6xHyidEwf2uWTMCd1eD87+4NX6Gee4+KbhI2
AfrkRbkJXhpSLkVA5eZvMOjdBOijLSE3dAZ/3QTcbgo5TmSdVnZcfSREqZXf3AT0E+Z58KCVOzcT
0SbCghgTGV3iYovAwegFlbjxGCi1cuc+jL5oZeedaBIsDAEeumMkUXgKmQlU+pjqS7EmmwD9cNHz
fLANPFqMI0A/H/Q8Hg493I0hIOhw19wZy2Jgbir9t/a6/wXHOwuDQB0owL8vhbxgsXhgEIxFXIDP
1p7YCy4RzBhsvO1DzibW4f4H+HuW2tOab+1bZ0vz/Zh0FAQfiwbvU6JWO59rnK347QySCzDngzxX
DdyFW6FYdSnvqtjaC0ifb48MV5OQ5LoNH5HTJRIx+rLZx4rmChKK0X2tJmb1Las8/aW/NraHbFcj
KUrNit1NOeKIdWz6wDgKcS7O15bLzG0K+oDt2DzF1eMmFf0pf32IIK96FJYTl7/l7nDE9PX7jZMM
hXry4wnB6CY7t2/O3ILr0OPPxAcQjyjPUPyF5nqfaFGk72c+n22OM8Aq2kdAi+uT/xbRBXo+I/lP
lFgLYjGgvYGjYybDLRp9TOrxPwYtUKAAQeHha+H9DqBHjw49trf6MeiMGTNmzJiRAf8Altc5UdjN
Fd8AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDUtMTVUMTM6MTM6MTErMDA6MDCWMLIMAAAAJXRF
WHRkYXRlOm1vZGlmeQAyMDIzLTA1LTE1VDEzOjEzOjExKzAwOjAw520KsAAAAABJRU5ErkJggg==" />
            </svg> : ""}</label>
        <input
          ref={addressFieldRef}
          type="text"
          id="autocomplete-address"
          placeholder={showInfoModal && location.href.includes("register-client") ? "Zip Code" : showInfoModal && location.href.includes("register-photographer") ? "Address" : ""}
          className={(!addressValue && locationErrorMessage) ? "form-control is-invalid" : "form-control"}
          value={addressValue || ""}
          autoComplete="off"
          onChange={(e) =>  {
            setAddressValue(e.target.value)
            if(page.includes('signup')){
              let value = e.target.value.trim();
              // if (!value) {
                if((oldAddressValue !== value) || !value)
                setSelectedAddress({
                  addrLine1: "",
                  city: "",
                  state: "",
                  country: "",
                  zip: "",
                  lat: "",
                  lng: "",
                });
              // }
            }
          }}
          onBlur={handleBlur}
        />
        {(addressValue && clearable) && (
          <span onClick={() => resetAddress()} style={{right: (showInfoModal && page == 'signup') ? "25px" : null,top: (showInfoModal && page == 'signup') ? "18px" : null ,zIndex: "99"}}>
            <i className="fa-solid fa-xmark"></i>
          </span>
        )}
        {showInfoModal && page== 'signup' ?<svg onClick={() => setZipModal(true)}
              width="18"
              height="18"
              className="ms-1"
              style={{ cursor: "pointer" }}
              version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 96 96" enableBackground="new 0 0 96 96">  <image id="image0" width="96" height="96" x="0" y="0"
                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ
cwAALEsAACxLAaU9lqkAAAAHdElNRQfnBQ8NDQt9sioqAAAEEElEQVR42u1b7VXjMBAcqEAdsB3g
EtzBpQNcgjuIr4L4KiAdhKuAXAUxFcRU4FwFuR8EkNZyIq2kKPeex3/0CCvN7K4+LQMzZsyYMWPG
f4y7yPUplCA8gkAA6PTXA3oc0OMNHToccou2o0SLPY4Ozw7PKHPT5dQHJ+r6s78NGQu8elM3o1Hl
JO+WMpdjkUFEGej5sQiSEZGMQgpL1JO/vmOLDv1p3PkAQYFApxFqCg1+XsP3NJk4W9RQDvYVXmLH
wR1P1vFmj8aBOpdhc8RwJrYRsIzcBe0ilqnor5L4yyZilYL+86iZjWfaTIGwHtX9HJv+KnGu1qO+
FTUKy1HeF7E9ZBndovWFejT9U3T6dglPcaodGH2VhD4AKOxYokZw1Z4lTzr6NgnB7S1ZdRE8cgE8
kYI6MwUElE67BP8Fc8GStpQLMH1Ri/3oO56Yw8arlH4VMLXwxbavFzeG9UImYC/OfmL0/WdWZaTR
XkLf9H/lZVtaltq+qENjsAvwQHgEADMDvPtBGeB/ILwPADwHPGtYB2agOQo1ghoAMwat3LQSNU5Y
Y8AR24ANT2PMQh4wE4jEBEKhpEmkJ9BLNvqA2Zda2z/cW830o4+8An5r5R+uRupGEmjMRbkZ6T1g
l5U+APTnpzNbChVa+T03f2MOJ38BW8iw/FrLDIHb804rP7qZvJ4PmgNWbB5uAgQs/FdU+iqoEDU6
MAFekxBDcX5NYEsh0sqHgKa/oQJsBQx038nQsAhI6xHyidEwf2uWTMCd1eD87+4NX6Gee4+KbhI2
AfrkRbkJXhpSLkVA5eZvMOjdBOijLSE3dAZ/3QTcbgo5TmSdVnZcfSREqZXf3AT0E+Z58KCVOzcT
0SbCghgTGV3iYovAwegFlbjxGCi1cuc+jL5oZeedaBIsDAEeumMkUXgKmQlU+pjqS7EmmwD9cNHz
fLANPFqMI0A/H/Q8Hg493I0hIOhw19wZy2Jgbir9t/a6/wXHOwuDQB0owL8vhbxgsXhgEIxFXIDP
1p7YCy4RzBhsvO1DzibW4f4H+HuW2tOab+1bZ0vz/Zh0FAQfiwbvU6JWO59rnK347QySCzDngzxX
DdyFW6FYdSnvqtjaC0ifb48MV5OQ5LoNH5HTJRIx+rLZx4rmChKK0X2tJmb1Las8/aW/NraHbFcj
KUrNit1NOeKIdWz6wDgKcS7O15bLzG0K+oDt2DzF1eMmFf0pf32IIK96FJYTl7/l7nDE9PX7jZMM
hXry4wnB6CY7t2/O3ILr0OPPxAcQjyjPUPyF5nqfaFGk72c+n22OM8Aq2kdAi+uT/xbRBXo+I/lP
lFgLYjGgvYGjYybDLRp9TOrxPwYtUKAAQeHha+H9DqBHjw49trf6MeiMGTNmzJiRAf8Altc5UdjN
Fd8AAAAldEVYdGRhdGU6Y3JlYXRlADIwMjMtMDUtMTVUMTM6MTM6MTErMDA6MDCWMLIMAAAAJXRF
WHRkYXRlOm1vZGlmeQAyMDIzLTA1LTE1VDEzOjEzOjExKzAwOjAw520KsAAAAABJRU5ErkJggg==" />
            </svg> : ""}
        {
          (!addressValue && locationErrorMessage) ? <p className="required_feild">{locationErrorMessage}</p> : ""
        }
      </div>
      {(showInfoModal && page == 'signup' && zipCodeError) && (
                <p className="required_feild text-start mb-4">{showInfoModal && location.href.includes("register-client") ? "Please click on a valid zip code from the drop down" : "Please click on a valid address from the drop down"}</p>
              )}
      <Modal
    opened={zipModal}
    onClose={() => setZipModal(false)}
    className="info-icon-cross"
    size="lg"
    zIndex={99999}
  >
    <p className="mt-2 mb-0">
      {window.location.href.includes('register-client') || page == 'client-profile' ? 'We use the zip code to display available photographers near you.' : ''}</p>
   
      {window.location.href.includes('register-client') || page == 'client-profile' ? ''
      :<>
        <p>We need your address to:</p>

        <ul>
          <li>Show you nearby photo shoots to book</li>
          <li>Conduct a background check for safety</li>
          <li>Set up your Stripe account for payouts</li>
          <li>Personalize our marketing efforts</li>
        </ul>

        Thanks for helping us make your experience safe and tailored!
      </>}
    <div className="row">
      <div className="col-md-12">
        <button
          style={{ marginTop: '20px' }}
          className="btn-w-full btn-gray b-shadow"
          onClick={() => setZipModal(false)}
        >
          Ok
        </button>
      </div>
    </div>

  </Modal>

    </>
  );
};



ProfileGoogleAddressPickerComponent.defaultProps = {
  address: "",
  showMap: true,
  inputLabel: "Address",
  className: "input-group form-floating mb-4",
  clearable: true,
  useDefaultCurrentLocation: true,
  showCurrentLocationButton: true,
};

export default ProfileGoogleAddressPickerComponent;
